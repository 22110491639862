import React from 'react'
import { Box, Typography, CardMedia, CssBaseline } from '@mui/material';
import MuiButton from '@mui/material/Button';
import Socials from './Socials';
import { styled } from '@mui/system';
import VideoCarousel from './VideoCarousel';
import tsVid from '../../assets/tsvid.mov';
import goodbik from '../../assets/TVids/goodbik.mov';
import wavej from '../../assets/TVids/wavej.mov';
import lawnj from '../../assets/TVids/lawnj.mov';
import CarouselTest from './CarouselTest';
import testimonial1 from '../../assets/testmoni1.png'
import testimonial2 from '../../assets/testmoni2.png'
import testimonial3 from '../../assets/testimoni3.png'
import testimonial4 from '../../assets/testimoni4.png'
import BouncingWordsAnimation from './BouncingWordsAnimation';
import { Link } from 'react-router-dom';



const SectionContainer = styled(Box)`
  padding: 0px;
  font-family: 'Exo', sans-serif;
`;

const SectionOneTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  color: #121212;
  font-weight: 400;
`;

const SectionThreeTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  font-weight: 400;
  color: white;
  background-color: #121212;
`;

const SectionTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  color: #121212;
  font-weight: 400;
`;

const SectionOneContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px 12px',
  columnGap: '1rem',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
}));

const SectionTwoContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '20px',
  color: '#121212',
  flexWrap: 'wrap-reverse',
  alignItems: 'center',
  marginBottom: '2rem',
  justifyContent: 'space-around',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const SectionThreeContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '20px',
  color: 'white',
  alignItems: 'center',
  backgroundColor: '#121212',
  marginBottom: '#2rem',
  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const SeeAllBtn = styled(MuiButton)(({theme}) => ({
    margin: '36px',
    color: 'white',
    fontSize: '16px',
    padding:'16px 48px',
    backgroundColor: '#121212',
    fontFamily: "'Exo', sans-serif",
    width: '20rem',
    alignSelf: 'center',
    "&:hover": {
      backgroundColor: 'white',
      color: '#ffc900',
    },
}));
    
const QuoteContainer = styled(Typography)(({ theme }) => ({
  fontSize: '48px',
  padding: '16px',
  width: '50%',
  fontFamily: "'Exo', sans-serif",
  fontStyle: 'italic',
  color: 'white',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: '24px',
    textAlign: 'center',
  },
}));

const TeamCard = styled(CardMedia) (({ theme }) => ({
  width: '20rem',
  backgroundColor: 'darkgrey',
  padding: '1rem',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const SectionZeroContent = styled(Box) ({
  fontFamily: "'Exo', sans-serif",
})

const ThriveTalks = () => {

    const videoArray = [goodbik, wavej, lawnj];

    const testimonials = [
        {
          imageUrl: testimonial1,
          name: 'John Doe',
          quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          imageUrl: testimonial2,
          name: 'Jane Smith',
          quote: 'Nulla facilisi. Sed non orci vel lectus efficitur fringilla.'
        },
        {
          imageUrl: testimonial3,
          name: 'Michael Johnson',
          quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
        },
        {
           imageUrl: testimonial4,
           name: 'Ron Johnson',
           quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
        }
      ];
  return (
    <Box className='thrive-talks'>
        <CssBaseline/>
        <Box className='all-sections-t'>
            <SectionContainer height='100vh' className='section-zero-t'>
                    <SectionZeroContent>
                    <Box sx={{width: {lg: '50%', xs: '100%'}}} width='50%' display='flex' flexDirection='column' justifyContent='center' height='100vh'  >
                      <Typography fontFamily="'Exo', sans-serif" sx={{padding: {sm: '1rem 10rem', xs: '1rem'}, textAlign: 'justify', color: 'black'}}>
                        Welcome to Thriving Solutions, a leading company established in
                        2022 and based in Windsor. Ontario, Canada. Led by Jermaine
                        Jackson, a well-known cancer ambassador, our company offers a
                        range of innovative solutions for body transformations, media, and
                        cancer cure research. We believe that everyone deserves to lead a
                        healthy and fulfilling life, and we are committed to providing our
                        clients with the tools and resources they need to achieve their
                        goals.
                        At Thriving Solutions, we understand that every person's journey
                        towards optimal health is unique, and that's why we offer
                        customized programs tailored to each individual's needs. Whether
                        you're looking to transform your body, advance your media presence,
                        or contribute to cancer cure research, our team of experts will work
                        with you every step of the way. With our cutting-edge technology
                        and innovative approaches, we're confident that we can help you
                        achieve your goals and thrive in all aspects of your life.
                      </Typography>
                      <SeeAllBtn  component={Link} to='/thrivebooking'>
                          Be on the Podcast
                      </SeeAllBtn>
                    </Box>
                    </SectionZeroContent>
            </SectionContainer>

            <SectionContainer>
                <SectionTitle>Thrive Talks</SectionTitle>
                    <SectionOneContent>
                      <BouncingWordsAnimation/>
                      <VideoCarousel videos={videoArray}/> 
                    </SectionOneContent>
            </SectionContainer>

            <SectionContainer>
                <SectionThreeTitle>Testimonials</SectionThreeTitle>
                    <SectionThreeContent>
                      <QuoteContainer>"We have two lives, and the second begins when we realize we only have one" - Confucious</QuoteContainer>
                      <CarouselTest testimonials={testimonials}/>
                    </SectionThreeContent>
            </SectionContainer>
        </Box>
        <Box textAlign='center' sx={{position: 'fixed', bottom: '2rem', left: 0, right: 0 }}>
            <Socials/>
        </Box>
    </Box>
  )
}

export default ThriveTalks