import React, { useState } from 'react';
import styled from "@emotion/styled/macro";
import ProductModal from './ProductModal';

const CardContainer = styled('div')`
  position: relative;
  width: 250px;
  height: 300px;
  overflow: hidden;
  cursor: pointer;
  margin-left: auto;
  margin-right: auto;
`;

const Image = styled('img')`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const CardOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;

const BuyNowText = styled('span')`
  font-size: 18px;
  font-weight: 100;
`;

const CardContent = styled('div')`
  padding: 10px;
  transition: opacity 0.3s ease-in-out;
  color: black;
`;

const Card = styled('div')`
  &:hover ${CardOverlay} {
    opacity: 1;
  }

  &:hover ${CardContent} {
    opacity: 1;
  }
`;

const ProductCardTwo = ({ product, onAddToCart}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const handleCardClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <Card onClick={handleCardClick}>
        <CardContainer>
          <Image src={product.image.url} alt={product.name} />
          <CardOverlay>
            <span>{product.price.formatted_with_symbol}</span>
            <BuyNowText>Buy Now</BuyNowText>
          </CardOverlay>
        </CardContainer>
        <CardContent>{product.name}</CardContent>
      </Card>
      {modalOpen && (
        <ProductModal product={product} onClose={handleCloseModal} onAddToCart={onAddToCart} />
      )}
    </>
  );
};

export default ProductCardTwo;
