import React from 'react';
import { styled, keyframes, css } from '@mui/system';
import './BouncingWords.css';
import { Box } from '@mui/material';

const BouncingWord = styled(Box) (({ theme }) => ({
  fontSize: '4rem',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    fontSize: '2.5rem',
  },
}));

const BouncingWordsAnimation = () => {
  const words = ['THE VOICE FOR THE VOICELESS', 'BE VULNERABALE', 'MENTAL HEALTH', 'YOU GOOD?', 'JUST CHECKING IN', 'EXPRESSIONS', 'COMMUNICATION'];

  return (
    <div className='bouncing-words'>
       <Box className='word-container'>
        {words.map((word, index) => (
            <BouncingWord className={`w${index}`} key={index}>{word}</BouncingWord>
        ))} 
      </Box>
    </div>
  );
};

export default BouncingWordsAnimation;
