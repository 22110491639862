import React from 'react';
import { styled } from '@mui/system';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import TestimonialCard from './TestimonialCard';
import { Button } from '@mui/material';

const CarouselContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

const CarouselButton = styled(Button)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  color: 'darkgrey',
  padding: 8,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
});

const TrioCarouselTest = ({ testimonials }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const handlePrevious = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? testimonials.length - 1 : prevSlide - 1
    );
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === testimonials.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <CarouselContainer>
      <CarouselButton onClick={handlePrevious}>
        <ArrowBackIos />
      </CarouselButton >
      <TestimonialCard
        name={testimonials[currentSlide].name}
        quote={testimonials[currentSlide].quote}
        imageUrl={testimonials[currentSlide].imageUrl}
      />
      <CarouselButton onClick={handleNext}>
        <ArrowForwardIos />
      </CarouselButton>
    </CarouselContainer>
  );
};

export default TrioCarouselTest;
