import React, { useState } from 'react';
import styled from "@emotion/styled/macro";
import { Box, Button, CircularProgress } from '@mui/material';

const ModalContainer = styled('div')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
`;

const ModalContent = styled(Box)(({theme}) => ({
  backgroundColor: '#fff',
  padding: '20px',
  borderRadius: '0px',
  display: 'flex',
  maxWidth: '400px',
  width: '100%',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: '1rem',
    alignItems: 'center',
    },
}));

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 32px;
  background-color: #121212;
  cursor: pointer;
  color: white;
`;

const ProductImage = styled('img')`
  width: 200px;
  height: auto;
  object-fit: cover;
  margin-right: 20px;
`;

const ProductInfo = styled('div')`
  flex: 1;
`;

const ProductName = styled('h2')`
  font-size: 18px;
  margin-bottom: 5px;
  color: black;
`;

const ProductPrice = styled('p')`
  font-size: 16px;
  margin-bottom: 10px;
  color: black;
`;

const ProductDescription = styled('p')`
  font-size: 14px;
  margin-bottom: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;
`;

const QuantityContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: black;
`;

const QuantityLabel = styled('span')`
  margin-right: 10px;
`;

const QuantityButton = styled('button')`
  font-size: 16px;
  padding: 5px 10px;
  background-color: #121212;
  border: none;
  cursor: pointer;
  margin-right: 5px; 
  color: white;
`;

const QuantityDisplay = styled('span')`
  margin-right: 5px; 
`;

const AddToCartButton = styled(Button)(({theme}) => ({
  padding: '10px 20px',
  backgroundColor: '#121212',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '0px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '12rem',
  [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
  },
  '&:hover': {
    backgroundColor: 'darkgray',
  },
}));

const ProductModal = ({ product, onClose, onAddToCart}) => {
  const [spinner, setSpinner] = useState('Add To Cart');
  const [quantity, setQuantity] = useState(1);

  const trimmedDescription = product.description.slice(3, -4);

  const handleIncrement = () => {
    setQuantity(quantity + 1);
  };

  const handleDecrement = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleAddToCart = () => {
     setSpinner(<CircularProgress color="inherit"/>);
    if (onAddToCart) {
      onAddToCart(product, quantity, setSpinner)
    }
  };

  return (
    <ModalContainer>
      <ModalContent>
        <ProductImage src={product.image.url} alt={product.name} />
        <ProductInfo>
          <CloseButton onClick={onClose}>x</CloseButton>
          <ProductName>{product.name}</ProductName>
          <ProductPrice>{product.price.formatted_with_symbol}</ProductPrice>
          <ProductDescription title={product.description}>{trimmedDescription}</ProductDescription>
          <QuantityContainer>
            <QuantityLabel>Quantity:</QuantityLabel>
            <QuantityButton onClick={handleDecrement}>-</QuantityButton>
            <QuantityDisplay>{quantity}</QuantityDisplay>
            <QuantityButton onClick={handleIncrement}>+</QuantityButton>
          </QuantityContainer>
          <AddToCartButton onClick={handleAddToCart}>{spinner}</AddToCartButton>
        </ProductInfo>
      </ModalContent>
    </ModalContainer>
  );
};

export default ProductModal;
