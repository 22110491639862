import React from 'react';
import {Typography, List, ListItem, ListItemText} from '@mui/material';
import {styled} from '@mui/system';
import '../NewComponents/NewComponents.css';

const ReviewTitle = styled('div') ({
	fontFamily:"'Exo', sans-serif",
	padding: '16px',
	fontSize: '24px',
	alignSelf: 'flex-start',
  });

const Review = ({checkoutToken, shippingData}) => {
	console.log(checkoutToken);
	return (
		<>
			<ReviewTitle color='black' variant='h6' gutterBottom>Order Summary</ReviewTitle>
			<List sx={{width: '100%'}} disablePadding>
				{checkoutToken.line_items.map((product) => (
					<ListItem xs={{padding: '10px 0'}} key={product.name}>
						<ListItemText disableTypography sx={{color: 'black', fontFamily:"'Exo', sans-serif"}} primary={product.name} secondary={` x ${product.quantity}`}/>
						<Typography sx={{fontFamily: "'Exo', sans-serif"}} color='black' variant="body2">{product.line_total.formatted_with_symbol}</Typography>
					</ListItem>									
				))}
				<ListItem xs={{padding: '10px 0'}} sx={{display: 'flex',justifyContent: 'space-between'}}>
					<Typography sx={{color: 'black', fontFamily: "'Exo', sans-serif"}}>Shipping</Typography>
					<Typography sx={{color: 'black', fontFamily: "'Exo', sans-serif"}} variant="body2" xs={{fontWeight: 700}}>
						{checkoutToken.shipping.price.formatted_with_symbol}
					</Typography>
				</ListItem>
				<ListItem xs={{padding: '10px 0'}}>
					<ListItemText disableTypography sx={{color: 'black', fontFamily: "'Exo', sans-serif"}} primary="Total"/>
					<Typography sx={{color: 'black', fontFamily: "'Exo', sans-serif"}} variant="substitle1" xs={{fontWeight: 700}}>
						{checkoutToken.total.formatted_with_symbol}
					</Typography>
				</ListItem>
			</List>
		</>
	)
}

export default Review;