import React from 'react'
import tsVid from '../../assets/tsvid.mov';
import { Box, CardMedia, Typography } from '@mui/material'
import { styled } from '@mui/system';


const BookVideo = styled(CardMedia) ({
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
});


const ThriveBooking = () => {
  return (
    <Box display='flex' flexDirection="column" rowGap='3rem' height='100vh'>
        <Typography textAlign='center' fontSize={48} color='white'>Thrive Bookings</Typography>
        <BookVideo component='video' src={tsVid} controls/>
        <Box marginBottom='2rem' fontSize={48} fontFamily='"Roboto","Helvetica","Arial",sans-serif' margin='0px auto' color='white'>
            Bookings Coming Soon!
        </Box>
    </Box>
  )
}

export default ThriveBooking