import React from 'react'
import { Box, Typography, CardMedia, CssBaseline} from '@mui/material';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/system';
import tSVid from '../../assets/tsvid.mov';
import at from '../../assets/TVids/attest.mp4';
import dl from '../../assets/TVids/dltest.mp4';
import mp from '../../assets/TVids/mptest.mp4';
import mcdr from '../../assets/TVids/mcdr.mp4';
import './NewComponents.css';
import Socials from './Socials';
import ProductCard from './ProductCard';
import testimonial1 from '../../assets/testmoni1.png'
import testimonial2 from '../../assets/testmoni2.png'
import testimonial3 from '../../assets/testimoni3.png'
import testimonial4 from '../../assets/testimoni4.png'
import Carousel from './Carousel';
import jermaine4 from '../../assets/j4.png';
import TrioCarouselTest from './CarouselTest';
import { Link } from 'react-router-dom';
import VideoCarousel from './VideoCarousel';



const SectionContainer = styled(Box)`
  padding: 0px;
  font-family: 'Exo', sans-serif;
`;

const SectionOneTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  color: #121212;
  font-weight: 400;
`;

const Welcome = styled(Typography)(({ theme }) => ({
    color: 'white',
    backgroundColor: '#121212',
    padding: '1rem',
    width: '70%',
    textAlign: 'justify',
    fontFamily: "'Exo', sans-serif",
}));

const SectionThreeTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  font-weight: 400;
  background-color: white;
  color: #121212;
`;

const SectionTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  font-weight: 400;
  color: white;
  background-color: #121212;
`;

const SectionOneContent = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  display: 'flex',
  columnGap: '1rem',
  justifyContent: 'center',
  color: 'white',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem'
  },
}));

const SectionTwoContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px 12px',
  fontSize: '50%',
  columnGap: '1rem',
  alignItems: 'flex-start',
  backgroundColor: '#121212',
  color: '#white',

  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const SectionFourContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '20px',
  backgroundColor: 'white',
  alignItems: 'center',
  color: '#121212',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const SectionThreeContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '24px 12px',
  fontSize: '50%',
  columnGap: '1rem',
  alignItems: 'center',
  marginTop: '4rem',
  backgroundColor: '#121212',
  justifyContent: 'space-around',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const SeeAllBtn = styled(MuiButton)(({theme}) => ({
color: 'white',
margin: '36px',
fontSize: '24px',
padding:'16px 48px',
backgroundColor: '#121212',
fontFamily: "'Exo', sans-serif",
"&:hover": {
  "&:hover": {
    backgroundColor: '#ffc900',
  },
},
}));

const QuoteContainer = styled(Typography)(({ theme }) => ({
  fontFamily: "'Exo', sans-serif",
  fontStyle: 'italic',
  fontSize: '48px',
  padding: '16px',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: '24px',
    textAlign: 'center',
  },
}));

const MediaContainer = styled(CardMedia)(({ theme }) => ({
  width: '100%',
  marginBottom: '2rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('lg')]: {

  },
}));

const MediaContainerTwo = styled(CardMedia)(({ theme }) => ({
  width: '40%',
  margin: '0rem 0rem 2rem 0rem',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const SectionZeroContent = styled(Box)(({ theme }) => ({
  [theme.breakpoints.down('lg')]: {
    height: '40rem',
  },
  [theme.breakpoints.down('md')]: {
    height: '30rem',
  },
  [theme.breakpoints.down('sm')]: {
    height: '20rem',
  },
}));

const ThriveMedia = () => {

    const videoArray = [at, dl, mp];

    const testimonials = [
        {
          imageUrl: testimonial1,
          name: 'John Doe',
          quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
        },
        {
          imageUrl: testimonial2,
          name: 'Jane Smith',
          quote: 'Nulla facilisi. Sed non orci vel lectus efficitur fringilla.'
        },
        {
          imageUrl: testimonial3,
          name: 'Michael Johnson',
          quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
        },
        {
           imageUrl: testimonial4,
           name: 'Ron Johnson',
           quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
        }
      ];
  
  return (
    <Box className='thrive-media'>
        <CssBaseline/>
        <div className='all-sections-m'>
            <SectionContainer className='section-zero-m'>
                    <SectionZeroContent>
                      <MediaContainer controls allow='autoplay' component='video' src={mcdr}/>
                    </SectionZeroContent>
            </SectionContainer>

            <SectionContainer>
                <SectionOneTitle>About Us</SectionOneTitle>
                    <SectionOneContent>
                    <Welcome>
                    Welcome to Thrive Media, where creativity knows no bounds. 
                    We pride ourselves on having the best creatives in the world, ready to transform your vision into a vibrant reality. 
                    With our extensive experience in commercial shoots, brand awareness campaigns, personal relations, and beyond, we have the expertise to elevate your business to new heights. 
                    Book a free 15-minute consultation with us today, and let's explore how Thrive Media can fuel the growth of your business.
                    </Welcome>
                    </SectionOneContent>
                    <Box width='100%' textAlign='center'>
                        <SeeAllBtn component={Link} to='/thrivebooking'>
                            Book Now
                        </SeeAllBtn>
                    </Box>
            </SectionContainer>

            <SectionContainer>
                    <SectionThreeContent>                  
                       <MediaContainerTwo component='img' src={jermaine4}/>
                       <VideoCarousel videos={videoArray}/>
                    </SectionThreeContent>
            </SectionContainer>

            <SectionContainer>
                <SectionThreeTitle>Testimonials</SectionThreeTitle>
                    <SectionFourContent>
                      <QuoteContainer>"The way to get started is to quit talking and begin doing" - Walt Disney</QuoteContainer>
                      <TrioCarouselTest testimonials={testimonials}/>
                    </SectionFourContent>
            </SectionContainer>
        </div>
        <Box textAlign='center' sx={{position: 'fixed', bottom: '2rem', left: 0, right: 0 }}>
            <Socials/>
        </Box>
    </Box>
  );
}

export default ThriveMedia;