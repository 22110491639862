import { styled } from '@mui/system';
import { Stepper, Paper } from '@mui/material';

export const CheckoutLayout = styled('div')(({ theme }) => ({
    toolbar: theme.mixins.toolbar,
    layout: {
      marginTop: '5%',
      width: 'auto',
      marginLeft: theme.spacing(2),
      marginRight: theme.spacing(2),
      [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
        width: 600,
        marginLeft: 'auto',
        marginRight: 'auto',
      },
    },
    paper: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      padding: theme.spacing(2),
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginTop: 60,
      },
      [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        padding: theme.spacing(3),
      },
    },
    stepper: {
      padding: theme.spacing(3, 0, 5),
    },
  }));

  export const StepperContainer = styled(Stepper) ({
    width: '50%',
    marginLeft: 'auto',
    marginRight: 'auto',
  })

  export const PaperContainer = styled(Paper) ({
    borderRadius: '0px',
    padding: '4px',
    boxShadow: 'none',
  })