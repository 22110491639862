import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {InputLabel, Select, MenuItem, Button, Grid, Typography, Box} from '@mui/material';
import {useForm, FormProvider} from 'react-hook-form';
import {commerce} from '../../commerce';
import { styled } from '@mui/system';
import FormInput from './FormInput';

const FormStyle = styled('form') ({
	padding: '8px 16px',
	fontFamily:"'Exo', sans-serif",
});

const AddressTitle = styled('div') ({
fontFamily:"'Exo', sans-serif",
  padding: '16px',
  fontSize: '24px',
});

const ButtonContainer = styled('div') ({
	display: 'flex',
	columnGap: '1rem',
	justifyContent: 'flex-end'
});

const ToCart = styled(Button)(({theme}) => ({
	color: '#121212',
	border: 'none',
	borderRadius: '0px',
	'&:hover': {
		color: 'white',
		backgroundColor: '#880808',
	}

}));

const ToPay = styled(Button)(({theme}) => ({
	color: 'white',
	backgroundColor: '#121212',
	borderRadius: '0px',
	'&:hover': {
		backgroundColor: 'green',
	}
	
}));



const AddressForm = ({checkoutToken, next}) => {
	const [shippingCountries, setShippingCountries] = useState([]);
	const [shippingCountry, setShippingCountry] = useState('');
	const [shippingSubdivisions, setShippingSubdivisions] = useState([]);
	const [shippingSubdivision, setShippingSubdivision] = useState('');
	const [shippingOptions, setShippingOptions] = useState([]);
	const [shippingOption, setShippingOption] = useState('');
	const methods = useForm();

	const countries = Object.entries(shippingCountries).map(([code, name]) => ({ id: code, label: name}));
	const subdivisions = Object.entries(shippingSubdivisions).map(([code, name]) => ({ id: code, label: name}));
	const options = shippingOptions.map((sO) => ({id: sO.id, label: `${sO.description} - (${sO.price.formatted_with_symbol})`}));

	const fetchShippingCountries = async (checkoutTokenId) => {
		const countries = await commerce.services.localeListShippingCountries(checkoutTokenId);
		setShippingCountries(countries.countries);
		setShippingCountry(Object.keys(countries.countries)[0]);
	}
	
	const fetchSubdivisions = async (countryCode) => {
		const subdivisions = await commerce.services.localeListSubdivisions(countryCode);		
		setShippingSubdivisions(subdivisions.subdivisions);
		setShippingSubdivision(Object.keys(subdivisions.subdivisions)[0]);
	}

	const fetchShippingOptions = async (checkoutTokenId, country, region = null) => {
		const options = await commerce.checkout.getShippingOptions(checkoutTokenId, {country, region});
		setShippingOptions(options);
		setShippingOption(options[0].id);
	}

	useEffect(() => {
		fetchShippingCountries(checkoutToken.id);
	}, []);

	useEffect(() => {
		if(shippingCountry) fetchSubdivisions(shippingCountry);
	}, [shippingCountry]);

	useEffect(() => {
		if(shippingSubdivision) fetchShippingOptions(checkoutToken.id, shippingCountry, shippingSubdivision);
	}, [shippingSubdivision]);

	return (
		<>
			<AddressTitle variant='h6' gutterBottom>Shipping Address</AddressTitle>
			<FormProvider {...methods}>
				<FormStyle onSubmit={methods.handleSubmit((data) => next({...data, shippingCountry, shippingSubdivision, shippingOption}))}>
					<Grid container spacing={3}>
						<FormInput required name='firstName' label='First name'/>
						<FormInput required name='lastName' label='Last name'/>
						<FormInput required name='address1' label='Address'/>
						<FormInput required name='email' label='Email'/>
						<FormInput required name='city' label='City'/>
						<FormInput required name='zip' label='ZIP/Postal code'/>
						<Grid item xs={12} sm={6}>
							<InputLabel sx={{fontFamily: "'Exo', sans-serif"}}>Shipping Country</InputLabel>
							<Select sx={{color: 'black'}} value={shippingCountry} fullWidth onChange={e => setShippingCountry(e.target.value)}>
 								{countries.map((country) => (
									<MenuItem sx={{color: 'black'}} key={country.id} value={country.id}>
										{country.label}
									</MenuItem>	
								))}								
							</Select>
						</Grid>
						<Grid item xs={12} sm={6}>
							<InputLabel sx={{fontFamily: "'Exo', sans-serif"}}>Shipping Subdivision</InputLabel>
							<Select sx={{color: 'black'}} value={shippingSubdivision} fullWidth onChange={e => setShippingSubdivision(e.target.value)}>
 								{subdivisions.map((subdivision) => (
									<MenuItem sx={{color: 'black'}} key={subdivision.id} value={subdivision.id}>
										{subdivision.label}
									</MenuItem>	
								))}								
							</Select>
						</Grid>
						<Grid item xs={12} sm={6}>
							<InputLabel sx={{fontFamily: "'Exo', sans-serif"}}>Shipping Options</InputLabel>
							<Select sx={{color: 'black'}} value={shippingOption} fullWidth onChange={e => setShippingOption(e.target.value)}>
								{options.map((option) => (
									<MenuItem sx={{color: 'black'}} key={option.id} value={option.id}>
										{option.label}
									</MenuItem>	
								))}	
							</Select>
						</Grid>
					</Grid>
					<br/>
					<ButtonContainer>
						<ToCart component={Link} to='/cart' variant="outlined">Back to Cart</ToCart>
						<ToPay type="submit" variant="contained">Next</ToPay>
					</ButtonContainer>
				</FormStyle>
			</FormProvider>
		</>
	);
}

export default AddressForm;