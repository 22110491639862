import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Container } from "@mui/material";
import ThriveTalks from "./components/NewComponents/ThriveTalks";
import ThriveMedia from "./components/NewComponents/ThriveMedia";
import ThriveStore from "./components/NewComponents/ThriveStore";
import { commerce } from "./commerce";
import Cart from "./components/Cart/Cart";
import Checkout from "./components/CheckoutForm/Checkout/Checkout";
import Transformations from "./components/NewComponents/Transformations";
import './App.css';
import Navbar from "./components/NewComponents/Navbar";
import ThriveBooking from "./components/NewComponents/ThriveBooking";

const App = () => {
    const [products, setProducts] = useState([]);
    const [cart, setCart] = useState({});
    const [order, setOrder] = useState({});
    const [errorMessage, setErrorMessage] = useState('');
    const [cartItemCount, setCartItemCount] = useState(0);


    const fetchProducts = async () => {
        const {data} = await commerce.products.list();
        setProducts(data);
    }

    const fetchCart = async () => {
        const cart = await commerce.cart.retrieve();
        setCart(cart);
        setCartItemCount(cart.total_items);
    }

    const handleAddToCart = async (productId, quantity, setSpinner) => {
        const response = await commerce.cart.add(productId, quantity);
        setCart(response); 
        setCartItemCount(response.total_items);
        setSpinner('Add To Cart');
         
    }
    const handleUpdateCartQty = async (lineItemId, quantity) => {
        const response = await commerce.cart.update(lineItemId, {quantity});
        setCart(response);  
        setCartItemCount(response.total_items);
    }

    const handleRemoveFromCart = async (productId) => {
        const cart = await commerce.cart.remove(productId);
        setCart(cart);
        setCartItemCount(cart.total_items);
    }

    const handleEmptyCart = async () => {
        const cart = await commerce.cart.empty();
        setCart(cart);
        setCartItemCount(cart.total_items);
    }

    const refreshCart = async () => {
        const newCart = await commerce.cart.refresh();
        setCart(newCart);
        setCartItemCount(newCart.total_items);
    }

    const handleCaptureCheckout = async (checkoutTokenId, newOrder) => {
        try {
                const incomingOrder = await commerce.checkout.capture(checkoutTokenId, newOrder);
                setOrder(incomingOrder);
                refreshCart();
        } catch (error) {
            setErrorMessage(error.data.error.message)
        }
    }

    useEffect(() => {
        fetchProducts();
        fetchCart();
    }, []);
    return ( 
        <Container disableGutters maxWidth={false} sx={{bgcolor: '#121212'}}>
            <Navbar cartItemCount={cartItemCount}/>
            <Routes>
                <Route exact path='/' element={<Transformations/>}/>
                <Route path='/thrivemedia' element={<ThriveMedia/>}/>
                <Route path='/thrivetalks' element={<ThriveTalks/>}/>
                <Route path='/thrivebooking' element={<ThriveBooking/>}/>
                <Route path='/thrivestore' element={<ThriveStore cart={cart} products={products} onAddToCart={handleAddToCart} />}/>
                <Route path='/cart' 
                       element={<Cart 
                                cart={cart} 
                                handleUpdateCartQty={handleUpdateCartQty} 
                                handleRemoveFromCart={handleRemoveFromCart} 
                                handleEmptyCart={handleEmptyCart}/>
                            }
                />
                <Route path='/checkout' element={<Checkout 
                                                 cart={cart}
                                                 order={order}
                                                 onCaptureCheckout={handleCaptureCheckout}
                                                 error={errorMessage}/>}
                />
            </Routes>
        </Container>
     );
}
 
export default App;