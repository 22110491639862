import React from "react";
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@emotion/react";
import { createTheme } from "@mui/material";

const theme = createTheme({
   /*palette: {
        pages: {
            transformation: '#9042f5',
            store: '#eb8034',
            about: '#827b6c',
            talks: '#d46ac0',
            media: '#1aad64'
        },
        text: {
            primary: '#ffffff',
            secondary: '#050004'
        },
        border: {
            primary: '#050004'
        },
        icon: {
            check: '#3aa128'
        },
        card: {
            bg: '#cd9732'
        },
        test: {
            bg: '#edd8ca'
        }
    },
    typography: {
        h6: {
            fontSize: '1.5rem',
            fontWeight: '400'
        },
        h5: {
            fontSize: '0.75rem',
            color: 'black',
            fontWeight: 'bold'
        },
        h2: {
            fontSize: '1.5rem',
            color: 'white',
            fontWeight: '400'
        },
        h4: {
            color: 'white',
            fontSize: '1rem'
        },
        h3: {
            fontSize: '2rem'
        }
    }*/
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);