import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Badge,
  Box,
  CardMedia
} from '@mui/material';
import { Menu as MenuIcon, ShoppingBasket as ShoppingBasketIcon } from '@mui/icons-material';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { Link } from 'react-router-dom';
import tsLogo from '../../assets/ThrivingSolutionsLogo.jpg';

const StyledAppBar = styled(AppBar)`
  background-color: #121212;
  box-shadow: none;
  z-index: 1000;
`;

const NavItem = styled(ListItem)(({theme}) => ({
    "&:hover": {
      backgroundColor: '#ffc900',
    }
}));

const Navbar = ({cartItemCount}) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [viewport, setViewport] = useState(window.innerWidth);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  return (
    <StyledAppBar position="sticky">
      <Toolbar>
        { viewport < 900 ?
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
          { isDrawerOpen ? <DisabledByDefaultIcon/> :<MenuIcon />}
          </IconButton> : 
          <List sx={{display: 'flex', flexGrow: 2, fontFamily:"'Exo', sans-serif"}}>
            <NavItem button component={Link} to='/'>
              <ListItemText disableTypography  primary="Transformations" />
            </NavItem>
            <NavItem button component={Link} to='/thrivemedia'>
              <ListItemText disableTypography primary="Thrive Media" />
            </NavItem>
            <NavItem button component={Link} to='/thrivestore'>
              <ListItemText disableTypography primary="Thrive Store" />
            </NavItem>
            <NavItem button component={Link} to='/thrivetalks'>
              <ListItemText disableTypography primary="Thrive Talks" />
            </NavItem>
          </List>

        }   
        <Button component={Link} to='/' variant="h6" sx={{ flexGrow: 1}}>
          <CardMedia sx={{width: '5rem'}} component='img' src={tsLogo}/>
        </Button>
        <Button
          sx={{fontFamily: "'Exo', sans-serif"}}
          component={Link}
          to="/cart"
          color="inherit"
          startIcon={
            <Badge sx={{fontFamily: "'Exo', sans-serif"}} badgeContent={cartItemCount} color="secondary">
              <ShoppingBasketIcon/>
            </Badge>
          }
        >
          Bag
        </Button>
      </Toolbar>
      <Drawer PaperProps={{
                            sx: {
                              backgroundColor: "black",
                              color: 'white',
                              marginTop: {md: '4rem', xs: '3rem'},                            
                            }   
                          }}
              anchor="left" 
              open={isDrawerOpen} 
              onClose={toggleDrawer}
            >
        <Box sx={{ width: '100vw'}}>
          <List sx={{fontFamily:"'Exo', sans-serif"}} >
            <NavItem  button component={Link} to='/'>
              <ListItemText onClick={toggleDrawer} disableTypography primary="Transformations" />
            </NavItem>
            <NavItem button component={Link} to='/thrivemedia'>
              <ListItemText onClick={toggleDrawer} disableTypography primary="Thrive Media" />
            </NavItem>
            <NavItem button component={Link} to='/thrivestore'>
              <ListItemText onClick={toggleDrawer} disableTypography primary="Thrive Store" />
            </NavItem>
            <NavItem button component={Link} to='/thrivetalks'>
              <ListItemText onClick={toggleDrawer} disableTypography primary="Thrive Talks" />
            </NavItem>
          </List>
        </Box>
      </Drawer>
    </StyledAppBar>
  );
};

export default Navbar;
