import React from 'react';
import { styled } from '@mui/system';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';

// Styled Card component
const StyledCard = styled(Card)(({ theme }) => ({
  width: '100%',
}));

// Styled CardMedia component
const StyledCardMedia = styled(CardMedia)({
});

// VideoCard component
const VideoCard = ({ videoUrl }) => {
  return (
    <StyledCard>
      <StyledCardMedia
        component="video"
        src={videoUrl}
        controls
        alt="Video"
      />
    </StyledCard>
  );
};

export default VideoCard;
