import React from 'react';
import { styled } from '@mui/system';
import { Box, CardMedia, Typography } from '@mui/material';

const CardContainer = styled(Box)({
  opacity: '0.9',
  width: '20rem',
  padding: '8px',
  display: 'flex',
  flexDirection: 'column',
  color: '#121212',
})
  

const ProductName = styled(Typography)(({theme}) => ({
  fontSize: '20px',
  fontFamily: "'Exo', sans-serif",
  textAlign: 'center',
  fontWeight: '400',
}))
  
const Price = styled('p')`
  font-size: 14px;
  font-weight: 200;
  text-align: center;
  color: black;
`;

const Description = styled(Typography)(({theme}) => ({
  fontSize: '12px',
  fontFamily: "'Exo', sans-serif",
  textAlign: 'center',
  fontWeight: '200',
  color: 'black',
}))

const StyledCardMedia = styled(CardMedia)({
  width: '50%',
  borderRadius: '10rem',
  alignSelf: 'center'
});

const ProductCard = ({ name, price, description, imageUrl, colors }) => {
  return (
    <CardContainer>
       <StyledCardMedia
        component="img"
        image={imageUrl}
        alt="Image"
      />
      <ProductName>{name}</ProductName>
      <Price>${price}</Price>   
      <Description>{description}</Description>
    </CardContainer>
  );
};

export default ProductCard;