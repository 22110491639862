import { Button, Box } from '@mui/material';
import { styled } from '@mui/system';

export const Toolbar = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

export const Title = styled('div')({
  marginTop: '5%',
  color: '#121212',
  fontSize: '28px',
  fontWeight: 'bold',
  marginBottom: '20px',
  fontFamily:"'Exo', sans-serif",
  textAlign: 'center',
});

export const EmptyButton = styled('button')(({ theme }) => ({
  minWidth: '200px',
  [theme.breakpoints.up('sm')]: {
    marginBottom: '5px',
  },
  [theme.breakpoints.up('sm')]: {
    marginRight: '20px',
  },
  backgroundColor: 'white',
  border: 'none',
  height: '40px',
  borderRadius: '4px',
  color: '#121212',
  cursor: 'pointer',
  fontWeight: 'bold',
  padding: '0 16px',
  textTransform: 'uppercase',
  fontFamily:"'Exo', sans-serif",
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: '#880808',
    color: 'white',
  },
}));

export const CheckoutButton = styled(Button)(({ theme }) => ({
  minWidth: '200px',
  backgroundColor: '#121212',
  border: 'none',
  height: '40px',
  fontFamily:"'Exo', sans-serif",
  borderRadius: '0px',
  color: 'white',
  cursor: 'pointer',
  fontWeight: 'bold',
  padding: '0 16px',
  textTransform: 'uppercase',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
  transition: 'background-color 0.3s ease',

  '&:hover': {
    backgroundColor: 'green',
  },
}));

export const LinkStyled = styled('a')({
  textDecoration: 'none',
  color: 'inherit',
  fontFamily: "'Exo', sans-serif",
});

export const CartStatement = styled('div')(({theme}) => ({
  fontFamily: "'Exo', sans-serif",
  color: '#121212',
  fontSize: '20px',
  textAlign: 'center',
  marginBottom: '20px',
 
}));

export const CardDetails = styled('div')(({theme}) => ({
  display: 'flex',
  marginTop: '10%',
  marginBottom: '1rem',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',

  '& > :first-of-type': {
    fontSize: '24px',
    fontWeight: 'bold',
  },

  '& > div': {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    rowGap: '1rem',

  }
}));


export const BackToStore = styled(Button) (({ theme }) => ({
  width: '15rem',
  backgroundColor: '#121212',
  border: 'none',
  height: '40px',
  color: 'white',
  cursor: 'pointer',
  fontWeight: 'bold',
  padding: '0 16px',
  textTransform: 'uppercase',
  transition: 'background-color 0.3s ease',
  alignSelf: 'center',
  fontFamily: "'Exo', sans-serif",
  borderRadius: '0px', 
  '&:hover': {
    backgroundColor: 'white',
    color: '#121212',
  },
  marginBottom: '24px',
}));

export const Subtotal = styled('div') ({
  color: '#121212',
  fontFamily:"'Exo', sans-serif",

});

export const BtnContainer = styled(Box)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
      flexDirection:'column',
      fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
    }
}));