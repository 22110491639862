import React from 'react';
import { styled } from '@mui/system';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import VideoCard from './VideoCard';

const CarouselContainer = styled('div')(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingTop: '3rem',
  paddingBottom: '3rem',
  width: '30rem',
  [theme.breakpoints.down('sm')]: {
    width: '20rem',
  },
}));

const CarouselButton = styled('button')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: 'transparent',
  border: 'none',
  cursor: 'pointer',
  outline: 'none',
  padding: 8,
  '&:hover': {
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
  },
  color: 'darkgrey',
});

const VideoCarousel = ({ videos }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);

  const handlePrevious = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === 0 ? videos.length - 1 : prevSlide - 1
    );
  };

  const handleNext = () => {
    setCurrentSlide((prevSlide) =>
      prevSlide === videos.length - 1 ? 0 : prevSlide + 1
    );
  };

  return (
    <CarouselContainer>
      <CarouselButton onClick={handlePrevious}>
        <ArrowBackIos />
      </CarouselButton>
      {videos
        .concat(videos.slice(0, 1)) // Duplicate first video to enable cycle
        .slice(currentSlide, currentSlide + 1)
        .map((videoUrl, index) => (
          <VideoCard key={index} videoUrl={videoUrl} />
        ))}
      <CarouselButton onClick={handleNext}>
        <ArrowForwardIos />
      </CarouselButton>
    </CarouselContainer>
  );
};

export default VideoCarousel;
