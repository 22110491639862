import React from 'react'
import { Box, Typography, CardMedia, CssBaseline} from '@mui/material';
import MuiButton from '@mui/material/Button';
import { styled } from '@mui/system';
import jermaine from '../../assets/jermain5.jpg';
import at from '../../assets/TVids/attest.mp4';
import dl from '../../assets/TVids/dltest.mp4';
import mp from '../../assets/TVids/mptest.mp4';
import tofficial from '../../assets/TVids/tofficial.MOV';
import trans1 from '../../assets/TPics/5.png';
import trans2 from '../../assets/TPics/6.png';
import trans3 from '../../assets/TPics/7.png';
import trans4 from '../../assets/TPics/8.png';
import trans5 from '../../assets/TPics/9.png';
import trans6 from '../../assets/TPics/11.png';
import trans7 from '../../assets/TPics/12.png';
import p1 from '../../assets/phase1.jpeg';
import p2 from '../../assets/phase2.jpeg';
import p3 from '../../assets/phase3.jpeg';
import './NewComponents.css';
import ProductCard from './ProductCard';
import Socials from './Socials';
import Carousel from './Carousel';
import VideoCarousel from './VideoCarousel';
import { Link } from 'react-router-dom';



const SectionContainer = styled(Box)`
  padding: 0px;
  font-family: 'Exo', sans-serif;
`;

const SectionOneTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  color: #121212;
  font-weight: 400;
`;

const SectionFourTitle = styled('h2')(({theme}) => ({
  fontSize: '48px',
  margin: '0px auto 0px auto',
  textAlign: 'center',
  padding: '16px',
  fontWeight: '400',
  color: 'white',
  backgroundColor: '#121212',
  [theme.breakpoints.down('sm')]: {
    fontSize: '36px',
  }
}));

const SectionTitle = styled('h2')`
  font-size: 48px;
  margin: 0px auto 0px auto;
  text-align: center;
  padding: 16px;
  background-color: '#121212';
  color: 'white';
`;

const SectionOneContent = styled(Box)(({ theme }) => ({
  fontSize: '16px',
  display: 'flex',
  columnGap: '1rem',
  justifyContent: 'center',
  color: 'white',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    alignItems: 'center',
    rowGap: '1rem'
  },
}));

const SectionTwoContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px 12px',
  fontSize: '50%',
  columnGap: '1rem',
  alignItems: 'flex-start',
  backgroundColor: '#121212',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}));

const SectionFourContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  fontSize: '20px',
  alignItems: 'center',
  backgroundColor: '#121212',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const SectionThreeContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  padding: '0px 12px',
  fontSize: '50%',
  columnGap: '1rem',
  alignItems: 'center',
  margin: '4rem auto',
  justifyContent: 'center',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
  [theme.breakpoints.down('sm')]: {
    width: '22rem',
  },
}));

const SeeAllBtn = styled(MuiButton)(({theme}) => ({
  color: 'white',
  margin: '36px',
  fontSize: '24px',
  padding:'16px 48px',
  backgroundColor: '#121212',
  fontFamily: "'Exo', sans-serif",
  "&:hover": {
      backgroundColor: '#ffc900',
    },

}));

const QuoteContainer = styled(Typography)(({ theme }) => ({
  fontFamily: "'Exo', sans-serif",
  fontStyle: 'italic',
  fontSize: '36px',
  color: 'white',
  padding: '36px',
  width: '50%',
  [theme.breakpoints.down('md')]: {
    width: '100%',
    fontSize: '24px',
    textAlign: 'center',
  },
}));

const MediaContainer = styled(CardMedia)(({ theme }) => ({
  width: '50%',
  height: '30rem',
  marginBottom: '2rem',
  [theme.breakpoints.down('lg')]: {
    width: '100%',
  },
}));

const MediaContainerTwo = styled('video')(({ theme }) => ({
  width: '100%',
  marginLeft: 'auto',
  marginRight: 'auto',
  [theme.breakpoints.down('lg')]: {

  },
}));

const SectionZeroContent = styled(Box)(({theme}) => ({
  [theme.breakpoints.down('lg')]: {
    height: '40rem',
  },
  [theme.breakpoints.down('md')]: {
    height: '30rem',
  },
  [theme.breakpoints.down('sm')]: {
    height: '20rem',
  },
}));

const Transformations = () => {
  const videoArray = [at, dl, mp];

  const testimonials = [
    {
      imageUrl: trans1,
      name: 'Kim',
      quote: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
    },
    {
      imageUrl: trans2,
      name: 'Hilly',
      quote: 'Nulla facilisi. Sed non orci vel lectus efficitur fringilla.'
    },
    {
      imageUrl: trans3,
      name: 'Bikram',
      quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
    },
    {
      imageUrl: trans5,
      name: 'Rachel',
      quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
    },
    {
       imageUrl: trans6,
       name: 'Jacintha',
       quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
    },
    {
      imageUrl: trans7,
      name: 'Holi',
      quote: 'Praesent vitae justo nec risus ullamcorper efficitur.'
    }
    
  ];
  return (
    <Box className='transformations'>
        <CssBaseline/>
        <div className='all-sections'>
            <SectionContainer className='section-zero'>
                    <SectionZeroContent>
                      <MediaContainerTwo controls autoPlay>
                        <source src={tofficial}/>
                      </MediaContainerTwo>
                    </SectionZeroContent>
            </SectionContainer>

            <SectionContainer>
                <SectionOneTitle>Featured</SectionOneTitle>
                    <SectionOneContent>
                        <ProductCard 
                        imageUrl={p1}
                        name={'Weight Loss'} 
                        price={'299'} 
                        description={[
                                      '3 Month Body Transformation', 
                                      '24/7 Mastermind Group Access', 
                                      'Comprehensive Blood Report', 
                                      'Project Kickoff Meeting w/ Jermaine', 
                                      'Progress Cinematic Video 1-2 min Long' 
                                    ]}
                        colors={{'color': 'peachpuff', 'shadow': '10px 10px 10px silver'}}
                        />
                        <ProductCard 
                        imageUrl={p2}
                        name={'Get Shredded'} 
                        price={'299'} 
                        description={
                                    [
                                      '3 Month Body Transformation', 
                                      '24/7 Mastermind Group Access', 
                                      'Comprehensive Blood Report', 
                                      'Project Kickoff Meeting w/ Jermaine', 
                                      'Progress Cinematic Video 1-2 min Long' 
                                    ]}
                        colors={{'color': 'bisque', 'shadow': '10px 10px 10px silver'}}

                        />
                        <ProductCard 
                        imageUrl={p3}
                        name={'Maintenance'} 
                        price={'299'} 
                        description={
                                    [
                                      '3 Month Body Transformation', 
                                      '24/7 Mastermind Group Access', 
                                      'Comprehensive Blood Report', 
                                      'Project Kickoff Meeting w/ Jermaine', 
                                      'Progress Cinematic Video 1-2 min Long' 
                                    ]}
                        colors={{'color': 'black', 'shadow': '10px 10px 10px silver'}}
                        />
                    </SectionOneContent>
                    <Box width='100%' textAlign='center'>
                        <SeeAllBtn component={Link} to='/thrivebooking'>
                            Book Now
                        </SeeAllBtn>
                    </Box>
            </SectionContainer>

            <SectionContainer>
                <SectionFourTitle>About Us</SectionFourTitle>
                    <SectionTwoContent>
                       <Typography sx={{
                          margin: {lg: '3rem 5rem', xs: '1rem'}, 
                          padding:{lg: '2rem 5rem', xs: '1rem'}, 
                          fontFamily: "'Exo', sans-serif", 
                          color: 'black', 
                          backgroundColor: 'white',
                          textAlign: 'justify',
                        }}>
                          Welcome to Thriving Solutions, a leading company established in 
                          2022 and based in Windsor, Ontario, Canada. Led by Jermaine 
                          Jackson, a well-known cancer ambassador, our company offers a 
                          range of innovative solutions for body transformations, media, and 
                          cancer cure research. We believe that everyone deserves to lead a 
                          healthy and fulfilling life, and we are committed to providing our 
                          clients with the tools and resources they need to achieve their 
                          goals.
                          At Thriving Solutions we understand that every person's journey 
                          towards optimal health is unique, and that's why we offer 
                          customized programs tailored to each individual's needs. Whether 
                          your looking to transform your body, advance your media presence, 
                          or contribute to cancer cure research, our team of experts will work 
                          with you every step of the way. With our cutting-edge technology 
                          and innovative approaches, we're confident that we can help you 
                          achieve your goals and thrive in all aspects of life. 
                        </Typography>         
                        <MediaContainer component='img' src={jermaine} controls/>
                    </SectionTwoContent>
            </SectionContainer>

            <SectionContainer>
                    <SectionThreeContent>                  
                      <VideoCarousel videos={videoArray}/>
                    </SectionThreeContent>
            </SectionContainer>

            <SectionContainer>
                <SectionFourTitle>Transformations</SectionFourTitle>
                    <SectionFourContent>
                      <QuoteContainer>Without a test there is no testimony - Mike Tyson</QuoteContainer>
                      <Carousel testimonials={testimonials}/>
                    </SectionFourContent>
            </SectionContainer>
        </div>
        <Box textAlign='center' sx={{position: 'fixed', bottom: '2rem', left: 0, right: 0 }}>
            <Socials/>
        </Box>
    </Box>
  );
}

export default Transformations