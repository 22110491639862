import { styled } from '@mui/system';
import { Typography, Button, Card, CardActions, CardContent, CardMedia } from '@mui/material';

const CartItemCard = styled(Card)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '20px',
  boxShadow: '0px 1px 6px rgba(0, 0, 0, 0.1)',
  transition: 'box-shadow 0.3s ease',
  width: '50%',
  alignSelf: 'center',

  '&:hover': {
    boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.2)',
  },

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

const CartContent = styled(CardContent)(({theme}) => ({
  display: 'flex',
  alignItems: 'center',
  flex: 1,

  '& > *': {
    marginRight: '10px',
  },

  justifyContent: 'space-between',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: '2rem',
  },
}));

const CartActions = styled(CardActions)(({theme}) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingLeft: '16px',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    rowGap: '2rem',
  },
}));

const QuantityButton = styled(Button)({
  fontSize: '16px',
  backgroundColor: 'transparent',
  border: '1px solid #121212',
  cursor: 'pointer',
  marginRight: '5px',
  borderRadius: '0px', 
  color: '#121212',
  lineHeight: '1',
  padding: '4px 4px',
  minWidth: '32px',
});

const RemoveButton = styled(Button)(({theme}) => ({ 
  backgroundColor: '#121212',
  borderRadius: '0px',
  fontFamily:"'Exo', sans-serif",
  '&:hover': {
    backgroundColor: '#880808',
  },
  [theme.breakpoints.down('md')]: {
    marginRight: '5px',
  },

}));

const QuantityDisplay = styled(Typography)(({theme}) => ({
  marginRight: '5px',
  alignSelf: 'center',
  color: '#121212',
}));


const QuantityContainer = styled('div')({
  display: 'flex',
});

const CartItem = ({ item, onUpdateCartQty, onRemoveFromCart }) => {
  return (
    <CartItemCard>
      <CardMedia component='img' image={item.image.url} alt={item.name} sx={{ width: 100, height: 100 }} />
      <CartContent>
        <div>
          <Typography variant="h6" sx={{ fontWeight: 'bold', fontFamily:"'Exo', sans-serif" }}>{item.name}</Typography>
          <Typography variant="body2" sx={{ color: '#666666', fontFamily:"'Exo', sans-serif" }}>{item.line_total.formatted_with_symbol}</Typography>
        </div>
        <CartActions>
          <QuantityContainer>
            <QuantityButton size="small" onClick={() => onUpdateCartQty(item.id, item.quantity - 1)}>-</QuantityButton>
            <QuantityDisplay color='secondary' sx={{ fontSize: '14px', fontWeight: 'bold' }}>{item.quantity}</QuantityDisplay>
            <QuantityButton size="small" onClick={() => onUpdateCartQty(item.id, item.quantity + 1)}>+</QuantityButton>
          </QuantityContainer>
          <RemoveButton variant="contained" size="small" onClick={() => onRemoveFromCart(item.id)}>Remove</RemoveButton>
        </CartActions>
      </CartContent>
    </CartItemCard>
  );
};

export default CartItem;
