import React, { useState } from 'react';
import { styled } from '@mui/system';
import ProductCardTwo from './ProductCardTwo';
import { Box, TextField } from '@mui/material';

const TabsContainer = styled('div')`
  display: flex;
`;

const Tab = styled('div')`
  padding: 10px;
  cursor: pointer;
  border: none;
  background-color: transparent;
  position: relative;
  color: black;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${({ active }) => (active ? '#000' : 'transparent')};
    transform: scaleX(${({ active }) => (active ? '1' : '0')});
    transform-origin: left center;
    transition: transform 0.3s ease-in-out;
  }  
`;

const ProductsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  width: 100vw;
  margin-top: 24px;
`;

const SearchBar = styled(TextField)(({theme}) => ({
  marginBottom: '16px',
  marginLeft: 'auto',
  marginRight: 'auto',
  width: '50%',
  border: '8px solid #121212',
  [theme.breakpoints.down('md')]: {
    width: '80%'
  }
}));
  

const StoreCategories = ({ products, onAddToCart }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleSearch = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredProducts = products.filter((product) =>
    product.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <Box display='flex' flexDirection='column' alignItems='center'>
      <SearchBar
        label="Search"
        variant="standard"
        InputProps={{
          disableUnderline: true,
        }}
        sx={{
          input: {
            color: '#121212',
            paddingLeft: '1rem',
          },
          "& label": {
            transformOrigin: "right",
            fontSize: "0.8rem",
            fontFamily: "'Exo', sans-serif",
            paddingLeft: '1rem',
            color: '#121212',
          },
          "& legend": {
            fontSize: "0.6rem",
            color: '#121212',
          },
        }}
        value={searchTerm}
        onChange={handleSearch}
      />
      <ProductsContainer>
        {filteredProducts.map((product) => (
          <ProductCardTwo
            key={product.id}
            product={product}
            onAddToCart={onAddToCart}
          />
        ))}
      </ProductsContainer>
    </Box>
  );
};

export default StoreCategories;
