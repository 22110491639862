import React from 'react';
import { styled } from '@mui/system';

const TestimonialCardContainer = styled('div')(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: 16,
  borderRadius: 8,
}));

const TestimonialImage = styled('img')({
  width: '90%',
  marginBottom: 16,
});

const TestimonialName = styled('div')({
  fontWeight: 'bold',
  marginBottom: 8,
});

const TestimonialQuote = styled('div')({
  fontStyle: 'italic',
  textAlign: 'center',
});

const FitnessTestimonialCard = ({ name, quote, imageUrl }) => {
  return (
    <TestimonialCardContainer>
      <TestimonialImage src={imageUrl} alt="Testimonial" />
      <TestimonialName>{name}</TestimonialName>
    </TestimonialCardContainer>
  );
};

export default FitnessTestimonialCard;
