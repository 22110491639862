import React from 'react';
import { Container, Typography, Button, Grid, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import CartItem from './CartItem/CartItem';
import { Toolbar, Title, EmptyButton, LinkStyled, CardDetails, CartStatement, BackToStore, Subtotal, BtnContainer, CheckoutButton } from './styles.js';

const Cart = ({ cart, handleUpdateCartQty, handleRemoveFromCart, handleEmptyCart }) => {
  console.log(cart.line_items);

  const renderEmptyCart = () => (
    <CartStatement variant="subtitle1">
      You have no items in your shopping cart,
      <LinkStyled to="/thrivestore"> start adding some</LinkStyled>!
    </CartStatement>
  );

  if (!cart.line_items) return 'Loading';

  const renderCart = () => (
  <Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white'}}>
      {cart.line_items.map((lineItem) => (
        <CartItem key={lineItem.id} item={lineItem} onUpdateCartQty={handleUpdateCartQty} onRemoveFromCart={handleRemoveFromCart} />
      ))}
      <CardDetails>
        <Subtotal>Subtotal: {cart.subtotal.formatted_with_symbol}</Subtotal>
        <BtnContainer>
          <EmptyButton size="large" type="button" variant="contained" color="secondary" onClick={handleEmptyCart}>
            Empty Cart
          </EmptyButton>
          <CheckoutButton component={Link} to="/checkout" size="large" type="button" variant="contained" color="primary">
            Checkout
          </CheckoutButton>
        </BtnContainer>
      </CardDetails>
    </Box>
  );

  return (
    <Box backgroundColor='white' padding='10px' display='flex' flexDirection='column' height='100vh'>
      <Toolbar/>
      <Title  variant="h3" gutterBottom>
        Your Shopping Cart
      </Title>
      <BackToStore component={Link} to="/thrivestore">Continue Shopping</BackToStore>
      {!cart.line_items.length ? renderEmptyCart() : renderCart()}
    </Box>
  );
};

export default Cart;
