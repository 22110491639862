import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Socials from './Socials'
import StoreCategories from './StoreCategories'
import { styled } from '@mui/system';

const SectionContainer = styled('div')`
  padding: 0px;
  font-family: 'Exo', sans-serif;
`;

const SectionZeroTitle = styled(Typography)(({theme}) => ({
  fontSize: '10rem',
  fontFamily: 'Horizon',
  color: 'honeydew',
  textAlign: 'center',
  paddingTop: '10rem',
  [theme.breakpoints.down('md')]: {
    paddingTop: '5rem',
  }
}));
  
const SectionOneContent = styled('div')`
  font-size: 16px;
  background-color: white;
  color: white;
  height: 100vh;
  padding-top: 4rem;
`;

const SectionZeroContent = styled(Box)(({theme}) =>({
  height: '100vh',
  [theme.breakpoints.down('lg')]: {
    height: '80vh',
  },
  [theme.breakpoints.down('md')]: {
    height: '50vh',
  }
})); 

const ThriveStore = ({products, onAddToCart, cart}) => {

  return (
    <Box className='thrive-store'>
        <Box className='all-sections-s' >
            <SectionContainer className='section-zero-s'>
                <SectionZeroContent>
                  <SectionZeroTitle variant='h2'>Our Store</SectionZeroTitle>                   
                </SectionZeroContent>
            </SectionContainer>
            <SectionContainer>
                <SectionOneContent>
                    <StoreCategories products={products} onAddToCart={onAddToCart}/>
                </SectionOneContent>
            </SectionContainer>
        </Box> 
        <Box textAlign='center' sx={{position: 'fixed', bottom: '2rem', left: 0, right: 0 }}>
            <Socials/>
        </Box>      
    </Box>
  )
}

export default ThriveStore