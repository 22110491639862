import { ButtonGroup, IconButton } from '@mui/material'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';
import React from 'react'
import  './NewComponents.css';

const Socials = () => {
  return (
    <ButtonGroup>
        <IconButton size='large'>
            <FacebookIcon className='social-icon'/>           
        </IconButton>
        <IconButton size='large'>
            <YouTubeIcon className='social-icon'/>
        </IconButton>
        <IconButton size='large'>
            <InstagramIcon className='social-icon'/>
        </IconButton>
    </ButtonGroup>
  )
}

export default Socials;