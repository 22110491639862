import React, { useState } from 'react';
import { styled } from '@mui/system';
import FitnessTestimonialCard from './FitnessTestimonialCard';
import { Box } from '@mui/material';


const CarouselContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '50%',
  color: 'white',
  paddingRight: '1rem',
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
}));

const ArrowButton = styled('button')(({theme}) => ({
  fontSize: '6rem',
  fontWeight: 'bold',
  color: 'white',
  border: 'none',
  cursor: 'pointer',
  borderRadius: '2rem',
  backgroundColor: '#121212',
  [theme.breakpoints.down('sm')]: {
    fontSize: '2rem',
  },
}));

const Carousel = ({ testimonials }) => {
  const [currentCardIndex, setCurrentCardIndex] = useState(0);

  const rotateToNextCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const rotateToPrevCard = () => {
    setCurrentCardIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  };

  return (
    <CarouselContainer>
      <ArrowButton onClick={rotateToPrevCard}>&#8249;</ArrowButton>
      <FitnessTestimonialCard {...testimonials[currentCardIndex]} />
      <ArrowButton onClick={rotateToNextCard}>&#8250;</ArrowButton>
    </CarouselContainer>
  );
};

export default Carousel;
