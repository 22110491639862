import React from 'react';
import { styled } from '@mui/system';
import { Box } from '@mui/material';

const TestimonialCardContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
  padding: 16,
  borderRadius: 8,
});

const TestimonialImage = styled('img')({
  width: '80%',
  borderRadius: '50%',
  marginBottom: 16,
});

const TestimonialName = styled('div')({
  fontWeight: 'bold',
  marginBottom: 8,
});

const TestimonialQuote = styled('div')({
  fontStyle: 'italic',
  fontSize: 14,
  textAlign: 'center',
});

const TestimonialCard = ({ name, quote, imageUrl, color }) => {
  return (
    <TestimonialCardContainer sx={{color: color}}>
      <TestimonialImage src={imageUrl} alt="Testimonial" />
      <TestimonialName>{name}</TestimonialName>
      <TestimonialQuote>{quote}</TestimonialQuote>
    </TestimonialCardContainer>
  );
};

export default TestimonialCard;
