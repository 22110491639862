import React, {useEffect, useState} from 'react';
import { Step, StepLabel, Typography, CircularProgress, Button, Divider, Box} from '@mui/material';
import AddressForm from '../AddressForm';
import PaymentForm from '../PaymentForm';
import { CheckoutLayout, PaperContainer, StepperContainer } from './styles';
import { commerce } from '../../../commerce';
import {Link, useNavigate} from 'react-router-dom';
import styled from '@emotion/styled';


const steps = ['Shipping address', 'Payment details'];

const BackHome = styled(Button)(({theme}) => ({
	backgroundColor: '#121212',
	color: 'white',
	border: '1px solid #121212',
	width: '10rem',
	fontFamily: "'Exo', sans-serif",
	'&:hover': {
		backgroundColor: 'white',
		color: '#121212',
		border: '1px solid #121212',
	}
}));

const Checkout = ({ cart, order, onCaptureCheckout, error }) => {
	const [activeStep, setActiveStep] = useState(0);
	const [checkoutToken, setCheckoutToken] = useState(null);
	const [shippingData, setShippingData] = useState({});
	const navigate = useNavigate();
	const classes = CheckoutLayout;
  
	useEffect(() => {
	  const generateToken = async () => {
		try {
		  const token = await commerce.checkout.generateToken(cart.id, { type: 'cart' });
		  console.log(token);
		  setCheckoutToken(token);
		} catch (error) {
		  navigate.pushState('/');
		}
	  };
  
	  generateToken();
	}, [cart]);
  
	const nextStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1);
	const backStep = () => setActiveStep((prevActiveStep) => prevActiveStep - 1);
  
	const next = (data) => {
	  setShippingData(data);
	  nextStep();
	};
  
	let Confirmation = () => (order.customer ? (
		<>
		  <Box>
			<Typography fontFamily="'Exo', sans-serif" variant="h5">Thank you for your purchase, {order.customer.firstname} {order.customer.lastname}!</Typography>
			<Divider className={classes.divider} />
			<Typography fontFamily="'Exo', sans-serif" variant="subtitle2">Order ref: {order.customer_reference}</Typography>
		  </Box>
		  <br />
		  <BackHome component={Link} variant="outlined" type="button" to="/">Back to home</BackHome>
		</>
	  ) : (
		<div className={classes.spinner}>
		  <CircularProgress />
		</div>
	  ));

	  if (error) {
		Confirmation = () => (
		  <>
			<Typography variant="h5">Error: {error}</Typography>
			<br />
			<Button component={Link} variant="outlined" type="button" to="/">Back to home</Button>
		  </>
		);
	  }
  
	const Form = () =>
	  activeStep === 0 
	  ? <AddressForm checkoutToken={checkoutToken} next={next} />
	  : <PaymentForm shippingData={shippingData} checkoutToken={checkoutToken} backStep={backStep} nextStep={nextStep} onCaptureCheckout={onCaptureCheckout} />
  
	return (
	  <>
		<div className={classes.toolbar} />
		<main className={classes.layout}>
		  <PaperContainer className={classes.paper}>
			<Typography fontFamily={"'Exo', sans-serif"} paddingTop='1rem' variant='h5' align="center">
			  Checkout
			</Typography>
			<StepperContainer activeStep={activeStep} className={classes.stepper}>
			  {steps.map((step) => (
				<Step key={step}>
				  <StepLabel sx={{fontFamily: "'Exo', sans-serif"}}>{step}</StepLabel>
				</Step>
			  ))}
			</StepperContainer>
			<Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '10vh'}}>
			{activeStep === steps.length ? <Confirmation/> : checkoutToken && <Form />}
			</Box>
		  </PaperContainer>
		</main>
	  </>
	);
  };
  
  export default Checkout;