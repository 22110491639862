import React from 'react';
import {Typography, Button, Divider, Box} from '@mui/material';
import {Elements, CardElement, ElementsConsumer} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js';
import { STRIPE_PUBLIC_KEY } from '../../constants';
import {styled} from '@mui/system';
import Review from './Review';
const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

const PaymentTitle = styled('div') ({
	fontFamily:"'Exo', sans-serif",
	padding: '16px',
	fontSize: '24px',
	alignSelf: 'flex-start'
  });

const PayFormContainer = styled('form') ({
	padding: '0px 16px',
	boxShadow: 'none',
	width: '100%',
});

const ButtonContainer = styled('div') ({
	display: 'flex',
	columnGap: '1rem',
	justifyContent: 'flex-end',
});

const BackButton = styled(Button)(({theme}) => ({
	color: '#121212',
	border: 'none',
	borderRadius: '0px',
	fontFamily:"'Exo', sans-serif",
	'&:hover': {
		color: 'white',
		backgroundColor: '#880808',
	}
})); 

const PayButton = styled(Button)(({theme}) => ({
	color: 'white',
	backgroundColor: '#121212',
	borderRadius: '0px',
	fontFamily:"'Exo', sans-serif",
	'&:hover': {
		backgroundColor: 'green',
	}
})); 

const PaymentForm = ({checkoutToken, shippingData, backStep, onCaptureCheckout, nextStep}) => {
	const handleSubmit = async (event, elements, stripe) => {
		event.preventDefault();

		if(!stripe || !elements) return;

		const cardElement = elements.getElement(CardElement);

		const {error, paymentMethod} = await stripe.createPaymentMethod({type: 'card', card: cardElement});

		if(error) {
			console.log(error);
		} else {
			const orderData = {
				line_items: checkoutToken.line_items,
				customer: { firstname: shippingData.firstName, lastname: shippingData.lastName, email: shippingData.email},
				shipping: { 
					name: 'Primary', 
					street: shippingData.address1, 
					town_city: shippingData.city,
					county_state: shippingData.shippingSubdivision,
					postal_zip_code: shippingData.zip,
					country: shippingData.shippingCountry,
				},
				fulfillment: { shipping_method: shippingData.shippingOption},
				payment: {
					gateway: 'stripe',
					stripe: {
						payment_method_id: paymentMethod.id,
					}
				}
			}
			onCaptureCheckout(checkoutToken.id, orderData);
			nextStep();
		}
	}
	return (
		<>
			<Review checkoutToken={checkoutToken} shippingData={shippingData}/>
			<Divider/>
			<PaymentTitle variant="h6" gutterBottom xs={{margin: '20px 0'}}>Payment Method</PaymentTitle>
			<Elements stripe={stripePromise}>
				<ElementsConsumer>
					{({elements, stripe}) => (
						<PayFormContainer onSubmit={e => handleSubmit(e,elements,stripe)}>
							<CardElement/>
                            <br/> <br/>
                            <ButtonContainer>
                                <BackButton variant='outlined' onClick={backStep}>Back</BackButton>
                                <PayButton type='submit' variant='contained' disabled={!stripe} color='primary'>
                                    Pay {checkoutToken.subtotal.formatted_with_symbol}
                                </PayButton>
                            </ButtonContainer>
						</PayFormContainer>
					)}
				</ElementsConsumer>
			</Elements>
		</>
	);
}

export default PaymentForm;